export const formatDate = (tanggal) => {
  const newDate = new Date(tanggal).toLocaleString("id-ID", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return newDate;
};
