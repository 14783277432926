import { useEffect, useState } from "react";
// import axios from "axios";
import { Axios } from "../../config/Axios";

import "./home.css";

import Dana from "../../components/dana/Dana";
import Details from "../../components/details/Details";
import Header from "../../components/header/Header";
import Locations from "../../components/locations/Locations";
import Networks from "../../components/networks/Networks";
import Topics from "../../components/topics/Topics";

function Home() {
  const [typeAgenda, setTypeAgenda] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [agendas, setAgendas] = useState([]);
  const [detailObject, setDetailObject] = useState(null);
  const [query, setQuery] = useState("");
  const [tanggal, setTanggal] = useState("");

  // console.log(query, "query", tanggal, "tanggal");
  // console.log(agendas, "agendas");
  // GET AGENDA
  useEffect(() => {
    setIsLoading(true);

    const fetchDataAgenda = async () => {
      switch (typeAgenda) {
        case "all":
          let allAgenda = [];
          if (query || tanggal) {
            allAgenda = await Axios.post("agendafilter/all", {
              query: query,
              tahun: tanggal,
            });
          } else {
            allAgenda = await Axios.get("agenda/all");
          }
          setAgendas(allAgenda.data.nodes);
          setIsLoading(false);
          break;
        case "covid":
          let covid = [];
          if (query || tanggal) {
            covid = await Axios.post("agendafilter/1", {
              query: query,
              tahun: tanggal,
            });
          } else {
            covid = await Axios.get("agenda/1");
          }
          setAgendas(covid.data.nodes);
          setIsLoading(false);
          break;
        case "sekolah":
          let sekolah = [];
          if (query || tanggal) {
            sekolah = await Axios.post("agendafilter/2", {
              query: query,
              tahun: tanggal,
            });
          } else {
            sekolah = await Axios.get("agenda/2");
          }
          setAgendas(sekolah.data.nodes);
          setIsLoading(false);
          break;
        case "komunitas":
          let komunitas = [];
          if (query || tanggal) {
            komunitas = await Axios.post("agendafilter/3", {
              query: query,
              tahun: tanggal,
            });
          } else {
            komunitas = await Axios.get("agenda/3");
          }
          setAgendas(komunitas.data.nodes);
          setIsLoading(false);
          break;
        case "bencana":
          let bencana = [];
          if (query || tanggal) {
            bencana = await Axios.post("agendafilter/4", {
              query: query,
              tahun: tanggal,
            });
          } else {
            bencana = await Axios.get("agenda/4");
          }
          setAgendas(bencana.data.nodes);
          setIsLoading(false);
          break;
        case "unit":
          let unit = [];
          if (query || tanggal) {
            unit = await Axios.post("agendafilter/5", {
              query: query,
              tahun: tanggal,
            });
          } else {
            unit = await Axios.get("agenda/5");
          }
          setAgendas(unit.data.nodes);
          setIsLoading(false);
          break;
        case "gebrag":
          let gebrag = [];
          if (query || tanggal) {
            gebrag = await Axios.post("agendafilter/6", {
              query: query,
              tahun: tanggal,
            });
          } else {
            gebrag = await Axios.get("agenda/6");
          }
          setAgendas(gebrag.data.nodes);
          setIsLoading(false);
          break;
        case "mitra":
          let mitra = [];
          if (query || tanggal) {
            mitra = await Axios.post("agendafilter/7", {
              query: query,
              tahun: tanggal,
            });
          } else {
            mitra = await Axios.get("agenda/7");
          }
          setAgendas(mitra.data.nodes);
          setIsLoading(false);
          break;

        default:
          let all = [];
          if (query || tanggal) {
            all = await Axios.post("agendafilter/all", {
              query: query,
              tahun: tanggal,
            });
          } else {
            all = await Axios.get("agenda/all");
          }
          setAgendas(all.data.nodes);
          setIsLoading(false);
          break;
      }
    };

    fetchDataAgenda();
  }, [typeAgenda, query, tanggal]);

  return (
    <div className="home">
      <Header setQuery={setQuery} setTanggal={setTanggal} />
      <div className="main-content">
        <Details
          agendas={agendas}
          typeAgenda={typeAgenda}
          setTypeAgenda={setTypeAgenda}
          isLoading={isLoading}
          setDetailObject={setDetailObject}
          detailObject={detailObject}
          setQuery={setQuery}
          setTanggal={setTanggal}
        />
        <Networks detailObject={detailObject} agendas={agendas} />
        <div className="right-content">
          <Dana detailObject={detailObject} />
          <Locations
            detailObject={detailObject}
            agendas={agendas}
            isLoading={isLoading}
          />
          <Topics />
        </div>
      </div>
      {/* <Timeline /> */}
    </div>
  );
}

export default Home;
