import { useState, useRef } from "react";
import { Home, Info, Instagram } from "@material-ui/icons";
import swal from "sweetalert";

import "./header.css";

function Header({ setQuery, setTanggal }) {
  const queryRef = useRef();
  const tanggalRef = useRef();

  const [isOpenTanggal, setIsOpenTanggal] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    if (isOpenTanggal) {
      if (tanggalRef.current.value.length < 4) {
        swal("Minimal 4 karakter!", "Misal: 2021", "error");
        return;
      }
      setTanggal(tanggalRef.current.value);
    }
    setQuery(queryRef.current.value);
  };

  return (
    <div className="header">
      {/* Left */}
      <div className="headerLeft">
        <div className="headerLeftLogo">
          {/* <Assignment className="icon" style={{ fontSize: 30 }} /> */}
          <a href="https://ctarsa.tms.my.id/">
            <h1 className="logoTitle">
              {/* <b>CTARSA</b> <span>SEARCHLIGHT</span> */}
              <span>SEARCHLIGHT</span>
            </h1>
          </a>
        </div>
        <form className="search">
          {/* <label htmlFor="inputText">recent news about</label> */}
          <input
            type="text"
            id="inputText"
            className="inputText"
            placeholder="Cari..."
            ref={queryRef}
          />
          <label
            htmlFor="tanggal"
            onClick={() => setIsOpenTanggal(!isOpenTanggal)}
          >
            [+] Tahun
          </label>
          {isOpenTanggal && (
            <input
              className="inputTanggal"
              type="number"
              min="2000"
              max={new Date().getFullYear()}
              maxLength="4"
              onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
              name="tanggal"
              id="tanggal"
              placeholder={`2015-${new Date().getFullYear()}`}
              ref={tanggalRef}
            />
          )}
          <button className="btn" onClick={handleClick}>
            Temukan
          </button>
        </form>
      </div>
      {/* Right */}
      <div className="headerRight">
        <a
          href="https://ctarsafoundation.org/"
          target="_blank"
          rel="noreferrer"
        >
          <Home className="icon" />
        </a>
        <a
          href="https://ctarsafoundation.org/#contact"
          target="_blank"
          rel="noreferrer"
        >
          <Info className="icon" />
        </a>
        <a
          href="https://www.instagram.com/ctarsafoundation/"
          target="_blank"
          rel="noreferrer"
        >
          <Instagram className="icon" />
        </a>
      </div>
    </div>
  );
}

export default Header;
