import React, { useEffect, useState } from "react";
import "./bangunChart.css";
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";

function BangunChart() {
  const [data, setData] = useState([]);
  const [type, setType] = useState("");
  const [kunci, setKunci] = useState("");

  useEffect(() => {
    switch (type) {
      case "index-pembangunan":
        setKunci("Index Pembangunan Manusia");
        const fetchPembangunan = async () => {
          const dataIndexPemb = await axios.get(
            "https://apictarsa.tms.my.id/api/databps/ipm"
          );
          setData(dataIndexPemb.data);
        };
        fetchPembangunan();
        break;
      case "penduduk-miskin":
        setKunci("Penduduk Miskin");
        const fetchPendMiskin = async () => {
          const dataPendMis = await axios.get(
            "https://apictarsa.tms.my.id/api/databps/pm"
          );
          setData(dataPendMis.data);
        };
        fetchPendMiskin();
        break;
      case "parah-miskin":
        setKunci("Keparahan Kemiskinan");
        const fetchKepMiskin = async () => {
          const dataKepMis = await axios.get(
            "https://apictarsa.tms.my.id/api/databps/kk"
          );
          setData(dataKepMis.data);
        };
        fetchKepMiskin();
        break;

      default:
        setKunci("Index Pembangunan Manusia");
        const fetchPembangunanDef = async () => {
          const dataIndexPemb = await axios.get(
            "https://apictarsa.tms.my.id/api/databps/ipm"
          );
          setData(dataIndexPemb.data);
        };
        fetchPembangunanDef();
        break;
    }
  }, [type]);

  return (
    <div className="wrapper-bar-chart">
      <div className="input-type">
        <button
          title="Index Pembangunan"
          className="index"
          onClick={() => setType("index-pembangungan")}
        ></button>
        <button
          title="Penduduk Miskin"
          className="penduduk"
          onClick={() => setType("penduduk-miskin")}
        ></button>
        <button
          title="Keparahan Kemiskinan"
          className="parah"
          onClick={() => setType("parah-miskin")}
        ></button>
      </div>
      <ResponsiveContainer width="97%" height="85%">
        <BarChart
          width={360}
          height={180}
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey={"Provinsi"}>
            <Label value={kunci} offset={5} position="insideBottom" />
          </XAxis>
          <YAxis />
          <Tooltip />
          {/* <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }}/> */}
          <ReferenceLine y={0} stroke="#000" />
          <Brush
            dataKey={"Provinsi"}
            height={11}
            stroke="#152935"
            endIndex={kunci === "Index Pembangunan Manusia" ? 5 : 5}
            fill="#264a60"
          />
          {/* <Bar dataKey="2018" fill="#8884d8" stackId="a" /> */}
          <Bar dataKey="2019" fill="#8884d8" />
          <Bar dataKey="2020" fill="#b53d00" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BangunChart;
