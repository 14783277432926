import BangunChart from "../barChart/BangunChart";
import "./dana.css";
import { formatCurrency } from "../../helpers/formatCurrency";

function Dana({ detailObject }) {
  return (
    <div className="dana">
      <div className="dana-header">
        <h2>{detailObject ? "LAPORAN DANA" : "DATA BPS"}</h2>
      </div>
      {detailObject ? (
        <div className="dana-content">
          <div className="wrapper-dana">
            <div className="dana-total">
              <h3>TOTAL DANA TERKUMPUL</h3>
              <span>Rp x.xxx.xxx.xxx</span>
              <h3>TOTAL DANA TERSALURKAN</h3>
              <span>Rp x.xxx.xxx.xxx</span>
            </div>

            <div className="dana-proyek">
              <h3>DANA PROGRAM TERKUMPUL</h3>
              <span>
                {typeof detailObject.dana_agenda.dana_agenda_terkumpul ===
                "string"
                  ? detailObject.dana_agenda.dana_agenda_terkumpul
                  : formatCurrency(
                      detailObject.dana_agenda.dana_agenda_terkumpul
                    )}
              </span>
              <h3>DANA PROGRAM TERSALURKAN</h3>
              <span>
                {typeof detailObject.dana_agenda.dana_agenda_tersalurkan ===
                "string"
                  ? detailObject.dana_agenda.dana_agenda_tersalurkan
                  : formatCurrency(
                      detailObject.dana_agenda.dana_agenda_tersalurkan
                    )}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <BangunChart />
      )}
    </div>
  );
}

export default Dana;
