// import React, { useState, useEffect } from "react";
import Carousel from "./Carousel";
// import axios from "axios";

// const SPLASHBASE_URL = "https://apictarsa.tms.my.id/api/agenda/6";

const GambarCarousel = ({ detailObject }) => {
  // console.log(detailObject["foto_aktivitas"].length, "foto");
  // const [imgList, setImgList] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get(SPLASHBASE_URL)
  //     .then((resp) => {
  //       const data = resp.data.nodes;
  //       // setImgList(resp.data.nodes);
  //       const filterData = data.filter((id) => id.id_agenda === 1579);
  //       setImgList(filterData[0]["foto_aktivitas"]);
  //     })
  //     .catch((err) => {
  //       console.log("Unable to Fetch Image from splashbase", err);
  //     });
  // }, []);

  return (
    <>
      {detailObject["foto_aktivitas"].length === 0 && (
        <div>Belum ada foto...</div>
      )}
      {detailObject["foto_aktivitas"].length > 0 && (
        <Carousel
          imgList={detailObject["foto_aktivitas"]}
          img_width={450}
          img_height={320}
          visibleImages={
            detailObject["foto_aktivitas"].length % 2 === 0
              ? detailObject["foto_aktivitas"].length + 1
              : detailObject["foto_aktivitas"].length
          }
          duration={300}
        />
      )}
    </>
  );
};

export default GambarCarousel;
