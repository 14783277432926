import React from "react";
import { useState, useEffect } from "react";
import ReactMapGL, { Marker, Popup } from "react-map-gl";
import mapboxgl from "mapbox-gl";

// eslint-disable-next-line import/no-webpack-loader-syntax
/* eslint import/no-webpack-loader-syntax: off */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function Map({ agendas }) {
  // console.log(agendas, "agendas");
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "93.5%",
    latitude: -0.789275,
    longitude: 113.921327,
    zoom: 2.7,
    // pitch: 50,
  });

  const [selectedLokasi, setSelectedLokasi] = useState(null);

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedLokasi(null);
      }
    };

    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <ReactMapGL
      {...viewport}
      mapStyle="mapbox://styles/syukurbaru/ckupren6x0r8j18qv6svjeckb"
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      onViewportChange={(newView) => setViewport(newView)}
    >
      {agendas &&
        agendas.map((agenda) => (
          <Marker
            key={agenda.id_agenda}
            latitude={Number(agenda.latitude)}
            longitude={Number(agenda.longitude)}
            offsetTop={(-viewport.zoom * 10) / 2}
            offsetLeft={(-viewport.zoom * 5) / 2}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                setSelectedLokasi(agenda);
              }}
              className="marker-btn"
              style={{ background: "none", border: "none", cursor: "pointer" }}
            >
              <img
                src="map.png"
                alt="finder map"
                width={viewport.zoom * 5}
                height={viewport.zoom * 5}
              />
            </button>
          </Marker>
        ))}
      {selectedLokasi ? (
        <Popup
          latitude={Number(selectedLokasi.latitude)}
          longitude={Number(selectedLokasi.longitude)}
          onClose={() => setSelectedLokasi(null)}
        >
          <div style={{ width: "250px" }}>
            <h2 style={{ fontSize: "16px" }}>{selectedLokasi.kegiatan}</h2>
            <span style={{ fontSize: "10px" }}>
              {selectedLokasi.desc_kegiatan
                ? selectedLokasi.desc_kegiatan
                : "Berikut ini deskripsi kegiatan yang sedang dilaksanakan oleh CT Arsa"}
            </span>
            <img
              src={
                selectedLokasi.foto_aktivitas.length > 0
                  ? selectedLokasi.foto_aktivitas[0].link_foto_agenda
                  : "https://foto2.data.kemdikbud.go.id/getImage/69986890/1.jpg"
              }
              alt=""
              width="100%"
              height="150px"
              style={{ objectFit: "cover", marginTop: "7px" }}
            />
          </div>
        </Popup>
      ) : null}
    </ReactMapGL>
  );
}

export default Map;
