// import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
// import { useRef, useState } from "react";
import "./networks.css";
import Map from "../map/Map";
import GambarCarousel from "../carousel/GambarCarousel";

function Networks({ detailObject, agendas }) {
  // const [slideNumber, setSlideNumber] = useState(0);

  // const listRef = useRef();

  // const handleClick = (direction) => {
  //   let distance = listRef.current.getBoundingClientRect().x - 550;
  //   console.log(distance);

  //   if (direction === "left" && slideNumber > 0) {
  //     setSlideNumber(slideNumber - 1);
  //     listRef.current.style.transform = `translateX(0px)`;
  //   }

  //   if (direction === "right") {
  //     setSlideNumber(slideNumber + 1);
  //     listRef.current.style.transform = `translateX(${-590 + distance}px)`;
  //   }
  // };

  return (
    <div className="networks">
      <div className="networks-header">
        <h2>KEGIATAN</h2>
      </div>
      {detailObject ? (
        <div className="networks-graph">
          <GambarCarousel detailObject={detailObject} />
          {/* <div className="container-slider">
            <div className="arrow left" onClick={() => handleClick("left")}>
              <ArrowLeftOutlined />
            </div>
            <div className="wrapper" ref={listRef}>
              {detailObject ? (
                detailObject["foto_aktivitas"].map((item) => (
                  <div className="slide" key={item["id_foto_aktivitas"]}>
                    <img
                      src={
                        item["link_foto_agenda"] ||
                        "https://ctarsafoundation.org/template/front/img/peta.png"
                      }
                      onError={(e) =>
                        (e.target.src =
                          "https://ctarsafoundation.org/template/front/img/peta.png")
                      }
                      alt={item["agenda_id"]}
                    />
                  </div>
                ))
              ) : (
                <div className="slide">
                  <img
                    src={
                      "https://ctarsafoundation.org/template/front/img/peta.png"
                    }
                    alt="ct arsa foudation"
                  />
                </div>
              )}
            </div>
            <div className="arrow right" onClick={() => handleClick("right")}>
              <ArrowRightOutlined />
            </div>
          </div> */}
        </div>
      ) : (
        <Map agendas={agendas} />
      )}
    </div>
  );
}

export default Networks;
